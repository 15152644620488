import { ROUTES } from '../../constant';

export interface CustomerRoutesConfig {
  excludedRoutes: string[];
  customHomepage?: {
    loggedIn: string;
    anonymous: string;
  };
}

export const routesCustomization: { [key: string]: CustomerRoutesConfig } = {
  mntp: {
    excludedRoutes: [
      `${ROUTES.BASE.EVENTS}`,
      `${ROUTES.BASE.SINGLE_EVENT}`,
      '^/@.*$', //`${ROUTES.BASE.CONTRIBUTOR_PAGE}`,
      `${ROUTES.BASE.CONTRIBUTOR_PAGE_ID}`,
      '/event/*',
    ],
    customHomepage: {
      loggedIn: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}`,
      anonymous: `${ROUTES.AUTH.LOGIN_PAGE}`,
    },
  },
};
