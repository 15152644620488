export * from './Routes';
export * from './Events';
export * from './Maps';

const overrides: any = {
  anto: {
    signInTitle: 'Anto',
    logoWhite: '/images/anto/logo_text_white.svg',
    logo: '/images/anto/logo.svg',
    authenticationBackground: '/images/anto/authentication_background.png',
    authenticationBackgroundOverlay: '/images/anto/authentication_background_overlay.svg',
    lottieAnimation: '/animations/anto/data.json',
    lottieAudio: '/animations/anto/audio.mp3',
    profileIcon: '/images/anto/profile_icon.svg',
    eventIconsDir: '/images/anto/event-icons',
    mapBounds: undefined,
    eventPhotoPlaceholder: '/images/anto/event-creation/event_image_placeholder.svg',
    showTimezone: true,
  },
  mntp: {
    signInTitle: 'Montpellier Agenda',
    logoWhite: '/images/mntp/logo_white.svg',
    logo: '/images/mntp/logo.png',
    authenticationBackground: '/images/mntp/authentication_background.svg',
    authenticationBackgroundOverlay: '/images/mntp/authentication_background_overlay.svg',
    lottieAnimation: '/animations/mntp/data.json',
    profileIcon: '/images/mntp/profile_icon.svg',
    eventIconsDir: '/images/mntp/event-icons',
    mapBounds: {
      north: 43.7149,
      south: 43.5069,
      east: 4.0132,
      west: 3.7412,
    },
    eventPhotoPlaceholder: '/images/mntp/event-creation/event_photo_placeholder.png',
    showTimezone: false,
  },
};

const currentEnvName: string = process.env.REACT_APP_ENV || 'anto';

const envVariables = overrides[currentEnvName] || {};

export default envVariables;
