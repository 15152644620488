import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const NavbarWrapper = styled.nav`
  display: none;
  width: 100%;
  padding: 0 25px;
  min-height: 82px;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeGet('backgrounds.navbar', '#fff')};
  @media only screen and (min-width: 991px) {
    display: flex;
  }

  &.is_transparent {
    background: transparent;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    border-bottom: 0;
    background-color: transparent;
    transition: all 0.3s ease-out;

    .ant-menu {
      li {
        &.ant-menu-item-selected {
          background-color: transparent;
        }

        a {
          color: ${themeGet('color.1', '#ffffff')};

          &.active {
            border-bottom: 0;
          }
        }
      }

      &.dropdown-menu {
        li {
          a {
            color: ${themeGet('text.0', '#2C2C2C')};
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;

  > a {
    margin-right: 27px;
  }
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  margin-left: 15px;

  .avatar-dropdown {
    position: relative;

    .dropdown-handler {
      height: 58px;
      background-color: #f6f5fa;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      border-radius: 29px;
      &.active {
        background-color: rgba(222, 222, 224, 0.9) !important;
      }
      &:hover {
        background-color: rgba(230, 231, 236, 0.5);
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 0;
      left: 0;
      top: 58px;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;
      border-radius: 10px;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }

      &.active {
        opacity: 1;
        visibility: visible;
      }

      li {
        color: ${themeGet('text.0', '#2C2C2C')};
        line-height: 18px;
        height: auto;
        padding: 0;
        transition: color 0.2s ease-in-out;

        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }

        a {
          display: block;
          padding: 8px 16px;
          color: ${themeGet('text.0', '#2C2C2C')};
          transition: color 0.2s ease-in-out;

          &:hover {
            color: ${themeGet('primary.0', '#EE3932')};
          }

          &.active {
            font-weight: 700;
            color: ${themeGet('primary.0', '#EE3932')};
          }
        }

        button {
          padding: 0;
          border: 0;
          border-top: 2px solid #e8e8e8;
          cursor: pointer;
          padding: 9px 16px;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export const ProfileAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeedf2;
  background-color: ${themeGet('backgrounds.profileIconBg', '#fff')};
  flex: 0 0 48px;
  img {
    width: 21px;
    height: 25px;
  }
`;

export const ProfilePseudo = styled.span`
  margin-left: 22px;
  margin-right: 5px;
  font-size: 16px;
  color: ${themeGet('primary.0', '#fff')};
  flex-grow: 1;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DropdownIcon = styled.div`
  margin-right: 20px;
  padding-top: 5px;
`;

export const MenuWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 14px;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      line-height: 18px;
      transition: color 0.2s ease-in-out;
      @media only screen and (max-width: 1200px) {
        margin: 0 10px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        background-color: transparent;
      }

      a {
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        paddign-left: 16px;
        padding-right: 5px;
        color: ${themeGet('primary.0', '#EE3932')};
        transition: color 0.2s ease-in-out;
        @media only screen and (max-width: 1200px) {
          padding-top: 30px;
          padding-bottom: 30px;
          paddign-left: 10px;
          padding-right: 5px;
        }

        &:hover {
          color: ${themeGet('primary.0', '#EE3932')};
        }

        &.active {
          font-weight: 700;
          color: ${themeGet('primary.0', '#EE3932')};
          border-bottom: 3px solid ${themeGet('primary.0', '#EE3932')};
        }
      }
    }
  }
`;

export const AuthWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    li {
      margin: 0;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      line-height: 18px;
      transition: color 0.2s ease-in-out;

      &.ant-menu-item-selected {
        background-color: transparent;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 78px;
        min-height: 38px;
        border-radius: 3px;
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        padding: 0 10px;

        &:hover {
          color: ${themeGet('primary.0', '#EE3932')};
        }
      }

      &:last-child {
        a {
          color: ${themeGet('color.1', '#ffffff')};
          background-color: ${themeGet('primary.0', '#EE3932')};
          transition: opacity 0.2s ease;
          padding: 0 10px;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
`;

export default NavbarWrapper;
